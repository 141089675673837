import {active} from "./const.js";
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var swiper = new Swiper("#js-homeBlogSwiper", {
  breakpoints: {
    768: {
      navigation: {
        nextEl: "#js-homeBlogTools .swiper-button-next",
        prevEl: "#js-homeBlogTools .swiper-button-prev",
      },
      spaceBetween: 30,
    },
  },
  slidesPerView: "auto",
  spaceBetween: 10,
});
