import $ from "jquery";

$('#js-searchButton').on('click', (event) => {

  // 専門ソリューションから絞り込む
  const specialized = $('[name=specialized]:checked').map(function() {
    return $(this).val();
  }).get();
  const specializedValue = specialized.join('_');

  const specialty = $('[name=specialty]:checked').map(function() {
    return $(this).val();
  }).get();
  const specialtyValue = specialty.join('_');

  if(specializedValue || specialtyValue) {
    let queryString = "?";
    if(specializedValue) {
      queryString += "specialized=" + specializedValue;

      if(specialtyValue) {
        queryString += "&specialty=" + specialtyValue;
      }
    } else if(specialtyValue) {
      queryString += "specialty=" + specialtyValue;
    }
    window.location.href = '/member' + queryString;
    return;
  }

  window.location.href = '/member';
});
